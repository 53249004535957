import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Import Components
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

class ParamInputs extends PureComponent {
    _compareArrays = (arr1, arr2) => {
        if (arr1.length !== arr2.length) {
            return false
        }

        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
                return false
            }
        }

        return true
    }


    handleParamsOnChange = (e, index) => {
        this.props.handleOnParamsChange(e, index)
    }

    handleRemove = (e, index) => {
        this.props.handleRemoveParamInput(e, index)
        // console.log('remove button pressed')
    }
    render() {

        const { handleAddParamInput, parsedFields } = this.props
        // console.log({ paramFields: parsedFields })
        return (
            <React.Fragment>
                    {
                        parsedFields.map((item, index) => {
                            return (
                                <Grid container key={'gridMain'+index}>
                                    <Grid item xs={4} key={'gridKey'+index}>
                                        <TextField
                                            key={index+'key'}
                                            id="outlined-textarea"
                                            placeholder="Key"
                                            variant="outlined"
                                            fullWidth={true}
                                            onChange={e => this.handleParamsOnChange(e, index)}
                                            name='key'
                                            value={item.key}
                                            size='small'
                                            style={{ paddingLeft: '4px', paddingRight: '4px' }}
                                        />
                                    </Grid>
                                    <Grid item xs={6} key={'gridValue'+index}>
                                        <TextField
                                            key={index+'value'}
                                            id="outlined-textarea"
                                            placeholder="Value"
                                            variant="outlined"
                                            fullWidth={true}
                                            onChange={e => this.handleParamsOnChange(e, index)}
                                            name='value'
                                            value={item.value}
                                            size='small'
                                        />
                                    </Grid>
                                    <Grid item xs={1} key={'gridPlus'+index}>
                                        <IconButton
                                            onClick={handleAddParamInput}
                                            color='primary'
                                            key={index+'plus'}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={1} key={'gridSub'+index}>
                                        <IconButton
                                            onClick={e => this.handleRemove(e, index)}
                                            color='secondary'
                                            key={index+'sub'}
                                        >
                                            <RemoveIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
            </React.Fragment>
        );
    }
}

ParamInputs.propTypes = {
    paramInputFields: PropTypes.array,
    handleAddParamInput: PropTypes.func,
    handleRemoveParamInput: PropTypes.func,
    handleParamChange: PropTypes.func,
};

export default ParamInputs;