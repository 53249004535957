import React, { PureComponent } from 'react';

// Import Components
import TextField from '@material-ui/core/TextField';

class URLTextfield extends PureComponent {
    handleOnInputChange = event => {
        this.props.handleOnApiInputChange(event.target.value)
    }
    render() {
        // const { inputValue, urlHandleChange, urlOnBlur } = this.props
        return (
            <React.Fragment>
                <TextField
                    id="outlined-textarea"
                    placeholder="Enter URL"
                    multiline
                    variant="outlined"
                    fullWidth={true}
                    value={ this.props.fullURL }
                    onChange={this.handleOnInputChange}
                    rowsMax={2}
                />
            </React.Fragment>
        );
    }
}

// URLTextfield.propTypes = {
//     inputValue: PropTypes.string,
//     urlHandleChange: PropTypes.func,
//     urlOnBlur: PropTypes.func
// };

export default URLTextfield;