import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './Theme/theme'
import Box from '@material-ui/core/Box';
import UserLayout from './Components/UserLayout/UserLayout';


function App() {
  return (
    <div className="App">
      <ThemeProvider
        theme={theme}
      >
        <Box display='flex' justifyContent='center'>
          <Box height='100vh' width='70%'>
            <UserLayout />
          </Box>
        </Box>
      </ThemeProvider>
    </div>
  );
}

export default App;
