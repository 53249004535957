import React, { PureComponent } from 'react';

// Import components
import Container from '@material-ui/core/Container';
import ReactJson from 'react-json-view'
import CircularProgress from '@material-ui/core/CircularProgress';

class ResponseLayout extends PureComponent {
    render() {
        const { response, responseTime, spinner } = this.props
        return (
            <Container style={{ height: '100%', width: '50%', overflow: 'auto', }}>

                {/* {responseTime ? <p>Response Time: {responseTime}ms</p> : <p>Response</p>}
                    {
                        response && responseTime &&
                        <React.Fragment>
                            <p>Response:</p>
                            <ReactJson src={this.props.response} />
                        </React.Fragment>
                    } */}
                {/* {
                    !response && 
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <h1 style={{ color: 'grey', fontWeight: 600 }}>No Response Yet</h1>
                        </div>
                } */}
                {  
                    spinner ?
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <CircularProgress />
                        </div>
                        :

                        <React.Fragment>
                            {
                                response && responseTime &&
                                <React.Fragment>
                                    <p style={{ color: 'green', opacity: '0.8', marginTop: '12px' }}>Response time: {responseTime} ms</p>
                                    <ReactJson src={response} />
                                </React.Fragment>
                            }
                        </React.Fragment>

                }
                {
                    !response && !responseTime &&
                    <div
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', color: 'gray' }}
                    >
                        <h4>Resonse empty!</h4>
                    </div>
                }
            </Container>
        );
    }
}

ResponseLayout.propTypes = {

};

export default ResponseLayout;