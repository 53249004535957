import React from 'react';
import PropTypes from 'prop-types';

// Import Components
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem'

class UserLayoutForm extends React.PureComponent {
    state = {
        label: 'Select',
        methods: [
            { id: 0, value: 'get', label: 'GET' },
            { id: 1, value: 'post', label: 'POST' },
        ],
        val: '',
        value: 'get'
    }

    // handleChnage = (e, items) => {
    //     console.log('handle change')
    //     items.map((item, index) => {
    //         if (item.value === e.target.value) {
    //             console.log('index: ', index);
    //             return this.setState({
    //                 val: item.value
    //             })
    //         }
    //     }
    //     )
    // }
    handleChange = e => {
        this.setState({
            value: e.target.value
        })
    }
    render() {
        const { selectedMethod, methods, handleSelectChange } = this.props
        return (
            <React.Fragment>
                <TextField
                    fullWidth
                    select
                    size='small'
                    variant='outlined'
                    value={ selectedMethod }
                    onChange={ handleSelectChange }
                >
                    {(!methods || methods.length === 0) &&
                        <MenuItem value=''><em>None</em></MenuItem>
                    }

                    {
                        methods.map(item =>
                            <MenuItem
                                value={item.value ? item.value : item} key={item.value}
                            >
                                {item.label ? item.label : item}
                            </MenuItem>
                        )
                    }
                </TextField>
            </React.Fragment>
        );
    }
}

UserLayoutForm.propTypes = {
    selectedMethod: PropTypes.string,
    methods: PropTypes.array,
    handleSelectChange: PropTypes.func
}

export default UserLayoutForm;