import React from 'react';
import PropTypes from 'prop-types';

// Import Components
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import Box from '@material-ui/core/Box';

class SendButton extends React.PureComponent {
    render() {
        const { handleSendButton } = this.props
        return (
            <React.Fragment>
                <Box marginTop={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<SendIcon />}
                        fullWidth={true}
                        onClick= { handleSendButton }
                    >
                        Send
                    </Button>
                </Box>
            </React.Fragment>
        );
    }
}

SendButton.propTypes = {
    handleSendButton: PropTypes.func
};

export default SendButton;