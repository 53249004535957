import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Marker, Popup } from 'react-map-gl';

class Pins extends PureComponent {
    state = {
        isMarkerClicked: false,
        lat: '',
        long: '',
        address: ''
    }

    check = (e, lat, long, address, index) => {
        this.setState({
            isMarkerClicked: true,
            lat: lat,
            long: long,
            address: address
        })
        console.log({ index })
    }
    popupOnClose = () => {
        this.setState({
            isMarkerClicked: false,
            lat: '',
            long: '',
            address: ''
        })
    }

    render() {
        const { locations } = this.props
        return (
            <React.Fragment>
                {
                    locations && locations.map((data, index) => {
                        return (
                            <Marker
                                key={`marker-${index}`} longitude={data.longitude} latitude={data.latitude}
                            >
                                <svg
                                    height={SIZE}
                                    viewBox="0 0 24 24"
                                    style={{
                                        cursor: 'pointer',
                                        fill: '#3f51b5',
                                        stroke: 'none',
                                        transform: `translate(${-SIZE / 2}px,${-SIZE}px)`
                                    }}
                                    onClick={e => this.check(e, data.latitude, data.longitude, data.address, index)}
                                >
                                    <path d={ICON} />
                                </svg>
                            </Marker>
                        )
                    })
                }
                {
                    this.state.isMarkerClicked &&
                    <Popup
                        latitude={this.state.lat}
                        longitude={this.state.long}
                        closeButton={true}
                        onClose={this.popupOnClose}
                        anchor='top-left'
                    >
                        <div>{this.state.address}</div>
                    </Popup>
                }
            </React.Fragment>
        )
    }
}

const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;

const SIZE = 20;

Pins.propTypes = {
    locations: PropTypes.array
};

export default React.memo(Pins);