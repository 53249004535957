import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
    pallete: {
        primary: {
            main: '#2ddbac',
        },
        secondary: {
            main: '#303840'
        },
    },

})

// theme props for components
theme.props = {
    MuiSelect: {
        MenuProps: {
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
            },
            getContentAnchorEl: null
        }
    },
    MuiMenuItem: {
        dense: true
    },
    MuiInputLabel: {
        margin: 'dense'
    },
    MuiFormControl: {
        variant: 'outlined',
        margin: 'dense',
        size: 'small',
        fullWidth: true
    },

    //Typography for placeholder
    MuiTypography: {
        display: 'inline',
        gutterBottom: true,
    },

    // Tabs menu
    MuiAppBar: {
        position: 'relative',
    },

    MuiTabs: {
        variant: 'fullWidth',
        scrollButtons: 'auto'
    }
}

// theme style overrides for components
theme.overrides = {
    MuiFormControl: {
        root: {
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    border: '1px solid',
                    borderColor: '#bae8e8',
                },
                '&:hover fieldset': {
                    border: '1px solid',
                    borderColor: '#29a19c',
                },
                '&.Mui-focused fieldset': {
                    border: '2px solid',
                    borderColor: 'rgba(45, 219, 172, 0.5)', //green
                },
            },
        }
    },
    MuiSelect: {
        root: {
            // background: 'rgba(56, 64, 78, 0.2)'
            background: '#3f51b5'
        },
        icon: {
            color: 'rgba(255, 255, 255, 0.8)'
        },
        outlined: {
            color: 'rgba(255, 255, 255, 0.8)',
            fontSize: '14px',
            borderRadius: '6px'
        },
        select: {
            '&:focus': {
                backgroundColor: '#3f51b5',
                borderRadius: '6px'
            }
        }
    },
    MuiMenuItem: {
        root: {
            fontSize: '12px',
            color: 'rgba(56, 64, 78, 0.8)',
        }
    },
    MuiInputLabel: {
        root: {
            fontSize: '14px'
        },
    },

    // Accordion Style
    MuiAccordion: {
        root: {
            background: 'transparent',
            boxShadow: 'none',
            width: '100%',
            '&$expanded': {
                margin: 'auto',
              },
        }
    },
    MuiAccordionSummary: {
        root: {
            background: 'rgba(56, 64, 78, 0.8)',
            color: 'white',
            borderLeft: '2px solid rgba(45, 219, 172, 0.8)',
            marginBottom: -1,
            height: '40px',
            minHeight: 24,
            '&$expanded': {
                minHeight: 24,
            },
        },
        content: {
            '&$expanded': {
                margin: 0,
            },
        },
        expandIcon: {
            "&$expanded": {
                transform: 'rotate(180deg)',
            },
            transition: 'transform 600ms',
        },
        expanded: {},
    },
    MuiAccordionDetails: {
        root: {
            color: '#ffffff',
            display: 'flex',
            flexDirection: 'column',
        }
    },

    //Typography for placeholder
    MuiTypography: {
        root: {
            padding: '6px 20px 3px',
            //paddingTop: '4px', 
            borderLeft: '2px solid rgba(45, 219, 172, 0.8)',
            background: 'rgba(56, 64, 78, 0.4)',
        }
    },

    // Tabs menu
    MuiAppBar: {
        root: {
            flexGrow: 1,
            maxWidth: '100%',
        },
        colorPrimary: {
            backgroundColor: 'rgba(56, 64, 78, 0.6)'
        }
    },

    MuiTabs: {
        root: {
            minHeight: '48px',
            height: '48px',
            color: 'white',
        },
        indicator: {
            background: 'rgba(45, 219, 172, 0.8)'
        }
    },

    MuiTab: {
        root: {
            minHeight: '48px !important',
            height: '48px !important',
            minWidth: '20px !important',
            width: '48px !important',
        },
        labelIcon: {
            fontSize: '8px'
        }
    },

    // DataGrid
    MuiDataGrid: {
        root: {
            color: 'white',
            '&.MuiCheckbox-root': {
                color: 'red !important'
            }
        },

    }

}

export default theme