export const convertFullUrl = testUrl => {
  const temp = testUrl.split('?')
  const baseUrl = temp.length > 0 ? temp[0] : testUrl

  const beforeParamsSplit = temp.length > 1 ? temp[1] : ''
  const paramsArray = beforeParamsSplit.split('&').filter(i => i)
  const paramValues = paramsArray.map(data => data.split('='))
  const keyValuesArray = paramValues.map(data => ({
    key: data.length > 0 ? data[0] : '',
    value: data.length > 1 ? data[1] : ''
  }))

  return {
    baseUrl,
    keyValuesArray: keyValuesArray.length === 0 ? [{ key: '', value: '' }] : keyValuesArray
  }
}

export const paramsToFullUrl = (baseUrl, params) => {
  const keyEqlsValueArray = params.map((data, index) => {
    return index === 0 ?
      // (data.key || data.value ? ('?' + data.key + '=' + data.value) : data.key) :
      // (data.key || data.value ? ('&' + data.key + '=' + data.value) : data.key)
      ('?' + data.key + '=' + data.value) :
      ('&' + data.key + '=' + data.value)
  })
  const fullUrl = baseUrl + keyEqlsValueArray.join('')
  return fullUrl === '?=' ? '' : fullUrl
}

export const responseToLatLong = response => {
  if (response.hasOwnProperty('places')) {
      const placesLatLong = response.places.map(data => {
          if (data.hasOwnProperty('latitude')) {
              return { latitude: Number(data.latitude), longitude: Number(data.longitude), address: data.address }
          }
          return false
      })
      return placesLatLong
  } else if (response.hasOwnProperty('place')) {
      if (response.place.hasOwnProperty('latitude')) {
          const placeLatLong = { latitude: Number(response.place.latitude), longitude: Number(response.place.longitude), address: response.place.address }
          return [placeLatLong]
      }
  } else if (response.hasOwnProperty('geocoded_address')) {
      const placeLatLong = { latitude: Number(response.geocoded_address.latitude), longitude: Number(response.geocoded_address.longitude), address: response.geocoded_address.Address }
      return [placeLatLong]
  }
  return false
}

export const getBounds = response => {
  if (response.length > 0) {
      const lat = response.map(data => data.latitude)
      const long = response.map(data => data.longitude)
      const maxLat = Math.max.apply(Math, lat)
      const minLat = Math.min.apply(Math, lat)
      const maxLong = Math.max.apply(Math, long)
      const minLong = Math.min.apply(Math, long)

      const southWest = [minLong, minLat];
      const northEast = [maxLong, maxLat];
      const bounds = [southWest, northEast];
      return bounds;
  }
  return response
  
}

// remove an element from an array
export const removeSelected = (array, index) => {
  if (array.length && array.length > 1) {
      array.splice(index, 1)
      return array
  }
  return array
}