import React, { PureComponent } from 'react';
import axios from 'axios';

// Import Components
import { convertFullUrl, paramsToFullUrl, responseToLatLong, } from '../Utils/utils';
import UserLayoutForm from './UserLayoutForm';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import URLTextfield from './URLTextfield';
import SendButton from './SendButton';
import ParamInputs from './ParamInputs';
import ResponseLayout from '../ResponseLayout/ResponseLayout';
import MapLayout from '../MapLayout/MapLayout';
import Paper from '@material-ui/core/Paper';


class UserLayout extends PureComponent {

    state = {
        methods: [
            { id: 0, value: 'get', label: 'GET' },
            { id: 1, value: 'post', label: 'POST' },
        ],
        selectedMethod: 'get',
        baseURL: '',
        fullURL: '',
        parsedFields: [{ key: '', value: '' }],
        response: '',
        responseTime: '',
        locations: [
            {
                address: 'Barikoi HQ (Barikoi.com), Wasi Tower, House 572/K, Matikata Main Road, Matikata, Dhaka Cantonment, Dhaka',
                latitude: 23.8216002775,
                longitude: 90.393801087233
            }
        ],
        spinner: false
    }

    componentDidUpdate(prevProps, prevState) {
        const { fullURL } = this.state;

        if (prevState.fullURL !== fullURL) {
            const parsedData = convertFullUrl(fullURL);
            this.setState({
                parsedFields: parsedData.keyValuesArray,
                baseURL: parsedData.baseUrl
            });
        }
    }

    handleOnApiInputChange = (value) => {
        this.setState({ fullURL: value });
    };

    handleOnParamsChange = (e, index) => {
        const { parsedFields, baseURL } = this.state;

        parsedFields.forEach((f, i) => {
            if (i === index) {
                if (e.target.name === "key") {
                    f.key = e.target.value;
                } else if (e.target.name === "value") {
                    f.value = e.target.value;
                }
            }
        });

        const newFullUrl = paramsToFullUrl(baseURL, parsedFields)
        this.setState({ fullURL: newFullUrl })
    };

    // add param input box 
    handleAddParamInput = () => {
        const { parsedFields } = this.state;
        this.setState({
            parsedFields: [...parsedFields, { key: '', value: '' }]
        })
    }

    // remove param input box
    handleRemoveParamInput = (e, index) => {
        const { parsedFields, baseURL } = this.state
        console.log('removed index num:', index)
        const values = [...parsedFields]
        values.length > 1 ? values.splice(index, 1) : console.log('hobena')
        this.setState({
            parsedFields: values,
            fullURL: paramsToFullUrl(baseURL, values)
        })
    }

    // full url 
    // https://barikoi.xyz/v1/api/search/autocomplete/MTk2NDo0OVRGSEdKSURL/place?q=shopno&latitude=23.873751&longitude=90.396454&scale=0.5
    // handle send button
    handleSendButton = () => {
        const startTime = Date.now();
        if (this.state.fullURL && this.state.selectedMethod && this.state.selectedMethod === 'get') {
            this.setState({
                locations: [],
                spinner: true
            })
            axios.get(`${this.state.fullURL}`)
                .then(res => {
                    const responseTime = Date.now() - startTime
                    const results = responseToLatLong(res.data)
                    this.setState({
                        response: res.data,
                        responseTime: responseTime,
                        locations: results,
                        spinner: false
                    })
                }).catch(err => {
                    const responseTime = Date.now() - startTime
                    // console.log({'get err': err.response.data})
                    this.setState({
                        response: err.response.data,
                        responseTime: responseTime,
                        spinner: false
                    })
                })
        } else if (this.state.fullURL && this.state.selectedMethod && this.state.selectedMethod === 'post') {
            this.setState({
                locations: [],
                spinner: true
            })
            axios.post(`${this.state.fullURL}`)
                .then(res => {
                    const responseTime = Date.now() - startTime
                    const results = responseToLatLong(res.data)

                    this.setState({
                        response: res.data,
                        responseTime: responseTime,
                        locations: results,
                        spinner: false
                    })
                }).catch(err => {
                    const responseTime = Date.now() - startTime
                    // console.log({'post err': err.response.data})
                    this.setState({
                        response: err.response.data,
                        responseTime: responseTime,
                        spinner: false
                    })
                })
        }
    }

    handleSelectChange = e => {
        this.setState({
            selectedMethod: e.target.value
        })
    }
    render() {
        return (
            <React.Fragment>
                <Box height='50%' marginTop={1}>
                    <Paper style={{ height: '98%' }} elevation={2}>
                        <Box paddingLeft={1} paddingRight={1} paddingTop={0}>
                            <Grid container spacing={1}>
                                <Grid container item xs={2} >
                                    <UserLayoutForm
                                        selectedMethod={this.state.selectedMethod}
                                        methods={this.state.methods}
                                        handleSelectChange={this.handleSelectChange}
                                    />
                                </Grid>
                                <Grid container item xs={8}>
                                    <URLTextfield
                                        fullURL={this.state.fullURL}
                                        handleOnApiInputChange={this.handleOnApiInputChange}
                                    />
                                </Grid>
                                <Grid container item xs={2} 
                                    justify='center'
                                    alignItems='center'
                                >
                                    <SendButton handleSendButton={this.handleSendButton} />
                                </Grid>
                            </Grid>
                        </Box>
                        {/* <Paper elevation={2}
                            style={{ height: '78%', width: '100%', overflowY: 'auto', overflowX: 'hidden', marginBottom: '4px', }}
                        > */}
                            <Box paddingLeft={1} paddingRight={1} 
                                style={{ height: '78%', width: '100%', overflowY: 'auto', overflowX: 'hidden', }}
                            >
                                <ParamInputs
                                    handleAddParamInput={this.handleAddParamInput}
                                    handleRemoveParamInput={this.handleRemoveParamInput}
                                    parsedFields={this.state.parsedFields}
                                    handleOnParamsChange={this.handleOnParamsChange}
                                />
                            </Box>
                        {/* </Paper> */}
                    </Paper>
                </Box>


                <Box height='48%'>

                    <Box display='flex' width='100%' height='100%'>
                        <Paper elevation={3} style={{ display: 'flex', width: '100%', background: 'white', }}>
                            <ResponseLayout
                                response={this.state.response}
                                responseTime={this.state.responseTime}
                                spinner={this.state.spinner}
                            />
                            <MapLayout
                                locations={this.state.locations}
                            />
                        </Paper>
                    </Box>
                </Box>
            </React.Fragment>
        );
    }
}

export default UserLayout;