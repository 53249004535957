import React, { PureComponent } from 'react';
import Box from '@material-ui/core/Box';
import ReactMapGL, { FlyToInterpolator, } from 'react-map-gl';
import Pins from './Pins'
class MapLayout extends PureComponent {
    state = {
        viewport: {
            width: 'fit',
            height: '100%',
            position: 'absolute',
            latitude: 23.8216002775,
            longitude: 90.393801087233,
            zoom: 14,
        },
        isMarkerClicked: false,
        lat: '',
        long: '',
        address: ''
    }

    componentDidUpdate(prevProps) {
        const { locations } = this.props
        const { viewport } = this.state

        if (!this._compareArrays(prevProps.locations, locations)) {
            if (locations.length > 0) {
                this.setState({
                    viewport: {
                        ...viewport,
                        latitude: locations[0].latitude,
                        longitude: locations[0].longitude,
                        zoom: 12,
                        transitionDuration: 400,
                        transitionInterpolator: new FlyToInterpolator()
                    }
                })
            }
        }
    }

    onViewportChange = viewport => {
        this.setState({ viewport })
    }

    _compareArrays = (arr1, arr2) => {
        if (arr1.length !== arr2.length) {
            return false
        }

        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i].longitude !== arr2[i].longitude || arr1[i].latitude !== arr2[i].latitude) {
                return false
            }
        }

        return true
    }
    
    render() {
        return (
            <Box height='100%' width='50%'>
                <ReactMapGL
                    {...this.state.viewport}
                    width='fit'
                    onViewportChange={this.onViewportChange}
                    // mapboxApiAccessToken={'pk.eyJ1IjoidGFyZXFoIiwiYSI6ImNra3FnNjY2dDAzcmIycHBjM2l0bGt1dzQifQ.URnYJHrVVHwCuTRRCe8ovQ'}
                    mapStyle='https://map.barikoi.com/styles/osm-liberty/style.json?key=MjYwMjpWVkVCS0dHQjkx'
                    // mapStyle='https://map.barikoi.com/styles/barikoi-dark/style.json'
                    
                >
                    {/* {
                        this.props.locations &&
                        this.props.locations.map((data, index) => {
                            return (
                                <Marker
                                    latitude={data.latitude}
                                    longitude={data.longitude}
                                    offsetLeft={-18.5}
                                    offsetTop={-20}
                                > */}
                                    {/* <FaMapMarkerAlt
                                        color={'black'} size={'1.8em'}
                                        onClick={ e => this.check( e, data.latitude, data.longitude, data.address, index ) }
                                    /> */}
                                    {/* <svg
                                        height={SIZE}
                                        viewBox="0 0 24 24"
                                        style={{
                                            position: 'absolute',
                                            cursor: 'pointer',
                                            fill: '#d00',
                                            stroke: 'none',
                                            // transform: `translate(${-SIZE / 2}px,${-SIZE}px)`
                                        }}
                                        onClick={ e => this.check( e, data.latitude, data.longitude, data.address, index ) }
                                    >
                                        <path d={ICON} />
                                    </svg> */}
                                {/* </Marker>
                            )
                        })
                    } */}
                    {
                        <Pins locations={this.props.locations} />
                    }
                    
                </ReactMapGL>
            </Box>
        );
    }
}


export default MapLayout;